import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import NumberInputSpinner from './NumberInputSpinner';
import print_settings from '../assets/print_settings.png';
import './FilePreview.css';
import '../Form.css';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const FilePreview = ({ file, preview, onRemove, formData, handleChange, onSave, isExpanded, onToggle, index }) => {
    const handlePreview = () => {
        window.open(preview, '_blank');
    };

    const handleSave = () => {
        onSave(formData);
        onToggle(index, false);
    };

    const handleCancel = () => {
        onToggle(index, false);
    };

    return (
        <Accordion expanded={isExpanded} onChange={() => onToggle(index)}>
            <AccordionSummary aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                <div className="file-preview-container file-preview-wrapper">
                    <div className="file-preview" onClick={handlePreview}>
                        <div className="file-preview-image">
                            {file.type.startsWith('image/') ? (
                                <img src={preview} alt={file.name} />
                            ) : (
                                <div className="file-icon">{file.name.split('.').pop().toUpperCase()}</div>
                            )}
                        </div>
                        <div className="file-preview-info">
                            <p className="file-name" title={file.name}>{file.name}</p>
                            <p className="file-size">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                        </div>
                    </div>
                    <div className="file-preview-actions">
                        {/* <button type="button" className="print-preferences">
                            <img src={print_settings} alt='print_settings' style={{ height: '25px', width: '25px' }} />
                        </button> */}
                        <button type="button" className="remove-file" onClick={onRemove}>
                            X
                        </button>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className="form-group">
                    <label htmlFor="orientation" className="form-label">Orientation:</label>
                    <select name="orientation" id="orientation" value={formData.orientation} onChange={handleChange} className="form-select" required>
                        <option value="Portrait">Portrait</option>
                        <option value="Landscape">Landscape</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="color_mode" className="form-label">Color Mode:</label>
                    <select name="color_mode" id="color_mode" value={formData.color_mode} onChange={handleChange} className="form-select" required>
                        <option value="Monochrome">Black and White</option>
                        <option value="Color">Color</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="copies" className="form-label">Number of Copies:</label>
                    <NumberInputSpinner
                        name="copies"
                        value={formData.copies}
                        onChange={handleChange}
                        min="1"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="duplex" className="form-label">Print Mode:</label>
                    <select name="duplex" id="duplex" value={formData.duplex} onChange={handleChange} className="form-select" required>
                        <option value="simplex">Print single side</option>
                        <option value="horizontal">Print both sides</option>
                        {/* <option value="vertical">Vertical</option> */}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="paper_size" className="form-label">Paper Size:</label>
                    <select name="paper_size" id="paper_size" value={formData.paper_size} onChange={handleChange} className="form-select" required>
                        <option value="A4">A4</option>
                        <option value="A3">A3</option>
                        <option value="Letter">Letter</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="start_page" className="form-label">Start Page:</label>
                    <NumberInputSpinner
                        name="start_page"
                        value={formData.start_page}
                        onChange={handleChange}
                        min="1"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="end_page" className="form-label">End Page:</label>
                    <NumberInputSpinner
                        name="end_page"
                        value={formData.end_page}
                        onChange={handleChange}
                        min="1"
                    />
                </div>
                <div className="button-group">
                    <button type="button" className="save-preferences-btn" onClick={handleSave}>
                        Save
                    </button>
                    <button type="button" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default FilePreview;